<div class="cardTitle" [style.background-color]="defConfig.color">
    <div class="cardTitle" [innerHTML]="defConfig?.title"></div>
</div>
<div class="cardPrice" [style.color]="defConfig.color" *ngIf="defConfig.price">
    <span>{{basket.currencySymbol}}</span>
    <span><app-currency [currency]="basket.currentCurrency" [currencyvalue]="defConfig.price"></app-currency></span>
    <span>
        <sub>{{'HT' | translate}}</sub>
        <span *ngIf="defConfig.priceAsterisk">{{defConfig.priceAsterisk}}</span>
    </span>
</div>
<div class="cardPrice" [style.color]="defConfig.color" *ngIf="!defConfig.price && defConfig.priceText">
    <span>{{defConfig.priceText}}</span>
</div>
<div class="cardLearnMore" *ngIf="defConfig.learnMore.text!='no'">
    <button class="btn" [style.border-color]="defConfig.color" (click)="defConfig.learnMore.action()">{{defConfig.learnMore.text | translate}}</button>
</div>
<div class="cardImg">
    <picture>
        <source srcset="assets/{{defConfig.img}}.webp" type="image/webp">
        <source srcset="assets/{{defConfig.img}}.jpg" type="image/jpg">
        <img src="assets/{{defConfig.img.name}}.jpg" class="w-100" alt="image {{defConfig.img.alt}}" title="{{defConfig.img.alt}}" loading="lazy">
    </picture>
</div>
<div class="cardDescription" [attr.rndId]="rndId" [innerHTML]="defConfig?.description | translate"></div>
<div class="cardButton">
    <button class="btn" [style.background-color]="defConfig.color" (click)="defConfig.mainButton.action()">{{defConfig.mainButton.text | translate}}</button>
</div>
<div class="cardDots" [ngClass]="{'pb-4':!defConfig.yellowText.text}">
    <div class="cardDot" *ngFor="let dot of defConfig.dots">
        <i class="cardDotPin fa fa-check-circle" [style.color]="defConfig.color"></i>
        <span class="cardDotText">{{dot.text | translate}}</span>
        <i class="fas fa-info dotInformation" *ngIf="dot.infoIconAction" (click)="dot.infoIconAction()" title="learn more"></i>
        <span class="cardDotText" *ngIf="dot.textAfterIcon">{{dot.textAfterIcon | translate}}</span>
    </div>
</div>

<div class="cardYellowFooter" *ngIf="defConfig.yellowText.text">
    <a [routerLink]="language.navigateAccordingToLang([defConfig.yellowText.route])" [state]="defConfig?.yellowText?.state">
        <i class="fas fa-arrow-alt-circle-right"></i>
        {{defConfig.yellowText.text | translate}}
    </a>
</div>
