import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { VrLogoComponent } from '../vr-logo/vr-logo.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasketService } from '../../services/basket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';


declare var $:any
export interface CardConfig{
    color?:string
    title: string | SafeHtml
    price?: number
    priceText?: string
    priceAsterisk?: string
    img: {
        name: string
        alt:string
    }
    description:string
    dots: {
        text: string
        infoIconAction?: () => void
        textAfterIcon?:string
    }[]
    yellowText: {
        text: string
        route: string;
        state?:object
        title:string
    }
    learnMore: {
        text?: string
        action: () => void
    }
    mainButton: {
        text?: string
        action: () => void
    }
}

@Component({
	selector: 'tarifs-card',
	templateUrl: './tarifs-card.component.html',
	styleUrls: ['./tarifs-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TarifsCardComponent implements OnChanges {
	constructor(
        private sanitizer: DomSanitizer,
        public basket: BasketService,
        public language : LanguageService,
        private translate: TranslateService,
    ) {}

    defConfig:CardConfig = {
        color: "#5cbfd4",
        title: "",
        price: 0,
        img: {
            name: "",
            alt: ""
        },
        description: "Lorem Ipsum",
        dots: [],
        yellowText: {
            text: "",
            route: "/",
            title: ""
        },
        learnMore: {
            text: this.translate.instant("En savoir plus"),
            action: ()=>{}
        },
        mainButton: {
            text: this.translate.instant("Commander_"),
            action: ()=>{}
        }
    }

    rndId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    @Input() config:CardConfig

	ngOnChanges(changes): void {
        for(let attr of Object.keys(this.config)){

            if(typeof this.defConfig[attr] == 'object' && !Array.isArray(this.defConfig[attr])){
                for(let attr2 of Object.keys(this.defConfig[attr])){
                    this.defConfig[attr][attr2] = this.config[attr][attr2] ?? this.defConfig[attr][attr2]
                }
            }
            else{
                this.defConfig[attr] = this.config[attr]
            }
        }
        this.defConfig.title = this.replaceVrIcon(this.defConfig.title)
    }

    replaceVrIcon(str:string|SafeHtml): SafeHtml{
        const newstr = str.toString().replace(/_vr_icon_/gm, new VrLogoComponent(this.sanitizer).getIconHTML(false).toString())
        return this.sanitizer.bypassSecurityTrustHtml(newstr.toString())
    }


    private _translate(text){
        return this.translate.instant(text)
    }
}
